import Typed from '../vendor/typed.min'

const ready = function() {

    $("#imgres").responsiveImages();

    // Collapse the navbar on scroll
    $(window).scroll(function() {
        if ($(".navbar").offset().top > 100) {
            return $(".navbar-fixed-top").addClass("top-nav-collapse");
        } else {
            return $(".navbar-fixed-top").removeClass("top-nav-collapse");
        }
    });

    // Make the home arrow down bouncing every 6s
    setInterval(function() {
        $(".arrow").removeClass("bounce");
        return setTimeout(() => $(".arrow").addClass("bounce")
        , 1000);
    }
    , 6000);

    $('.owl-fullscreen-home').owlCarousel({
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        loop: true,
        items: 1,
        lazyLoad: true,
        autoplay: true,
        autoplayTimeout: 5000,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut'
    });

    $('.owl-feedback-theme').owlCarousel({
        loop: true,
        margin: 10,
        autoHeight: true,
        autoplay: false,
        // autoplayTimeout: 8000
        // autoplayHoverPause: true
        // autoHeightClass: 'owl-height'
        nav: true,
        navText: [
            "<div class='ddd'><i class='fa fa-2x fa-chevron-left'></i></div>",
            "<div class='ddd'><i class='fa fa-2x fa-chevron-right'></i></div>"
        ],
        // stagePadding: 100,
        responsive: {
            0: {
                items: 1,
                stagePadding: 0
            },
            800: {
                items: 1,
                stagePadding: 120
            },
            1200: {
                items: 1,
                stagePadding: 240
            }
        }
    });

    // $('.owl-feedback-theme').waypoint
    //     handler: ->
    //         carousel = owl.data('owlCarousel')
    //         carousel.reinit
    //             autoplay: false
    //             autoplayTimeout: 8000
    //     offset: '30%'

    // Lazy load image/video elements
    $('.lazy').each(function() {
        const el = $(this);
        return setTimeout(() => el.attr('src', el.data('src'))
        , 750);
    });

    // $('[data-parallax="scroll"]').parallax();

    // $.each $('[data-parallax="scroll"]'), (i, elem) -> 
    //     $(elem).parallax(imageSrc: $(this).data("image-src"))

    // Typewriter effects
    if($("#typed-who").length){
        new Typed("#typed-who", { 
            stringsElement: '#typed-strings-who',
            typeSpeed: 60,
            backSpeed: 20,
            backDelay: 2000,
            startDelay: 500,
            shuffle: true,
            loop: true,
        });
    }

    if($("#typed-why").length){
        new Typed("#typed-why", { 
            stringsElement: '#typed-strings-why',
            typeSpeed: 60,
            backSpeed: 20,
            backDelay: 2000,
            startDelay: 500,
            shuffle: true,
            loop: true,
        });
    }

    // Video modal

    $('#myModal').on('hidden.bs.modal', function () {
        //$('#yt-player')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
        $('#yt-player')[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
    });

    return $('.fab').click(() => $('.radial').toggleClass('open'));
};

// ---------------------------------------------------------------
// Normal jQuery.ready()
// $(document).ready(ready);
// Required to work with turbolinks
// (fires every turbolink page change after loading)
$(document).on('turbolinks:load', ready);
// ---------------------------------------------------------------
