/* 
*
* jQuery ResponsiveImage plugin 
*
* La libreria deve prendere tutti gli elementi passati con il query selector di jquery
* per es => $("img").responsiveImages(); e renderli responsive in base ai dati specificati 
* nei data attribute di ogni immagine. gli sttributi seguono le media query di bootstrap 
* percò daranno data-xs, data-sm, data-md, data-lg
* La libreria prende la grandezza della finestra e carica l'immagine corrispondente inoltre 
* vi è un event listener che se la finestra viene resizata l'immagine cambia.
*
*/

if( typeof jQuery === 'undefined' )
	throw new Error("ResponsiveImages's JavaScript requires jQuery");

(function ($, undefined){

	$.fn.responsiveImages = function(params){
		
		function getDataAttr(){
			// get the width and return the rispective data
			var width = $(window).width();
			if(width < 768)	return 'data-xs';
			else if ( 768 < width && width < 992) return 'data-sm';
			else if ( 992 < width && width < 1200) return 'data-md';
			else return 'data-lg'; // >= 1200 
		};

		// init plugin global vars
		var last_data_attr = getDataAttr(), 
			// the variable => this , in this scope is/are the elements in the $ selector => $('img') 
			elements = this;

		// setup a window change width listener
		$(window).resize(function (){
			// get the width
			var new_data_attr = getDataAttr();
			// if the 'bootstrap media query width' changed
			if (last_data_attr != new_data_attr){
				//console.log(new_data_attr)
				last_data_attr = new_data_attr;
				// trigger the changedWidthEvent
				$( elements	).trigger( "changedWidthEvent", [ new_data_attr ] );
			}
		});

		return elements.each(function(){//function(index, html){
			// init all the elements
			var el = $(this); // or also $(html)
			el.attr('src', el.attr(last_data_attr));
			// add the listener to each element
			el.on("changedWidthEvent", function(e, data_attr){
				//console.log(new_data_attr)
				$(this).attr('src', $(this).attr(data_attr));
			});
		});
	};

}(jQuery));