/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// http://reed.github.io/turbolinks-compatibility/facebook.html
const ready = function() {

    const saveFacebookRoot = function() {
        if ($('#fb-root').length) {
            return this.fbRoot = $('#fb-root').detach();
        }
    };

    const restoreFacebookRoot = function() {
      if (this.fbRoot != null) {
        if ($('#fb-root').length) {
          return $('#fb-root').replaceWith(this.fbRoot);
        } else {
          return $('body').append(this.fbRoot);
      }
    }
  };

    const bindFacebookEvents = function() {
        $(document)
            .on('page:fetch', saveFacebookRoot)
            .on('page:change', restoreFacebookRoot)
            .on('turbolinks:load', () => typeof FB !== 'undefined' && FB !== null ? FB.XFBML.parse() : undefined);
        return this.fbEventsBound = true;
    };

    const initializeFacebookSDK = () => FB.init({
        status : true,
        cookie : true,
        xfbml  : true,
        version : 'v2.5'
    });

    const loadFacebookSDK = function() {
        window.fbAsyncInit = initializeFacebookSDK;
        return $.getScript("//connect.facebook.net/en_US/sdk.js");
    };

    // Load FB SDK
    loadFacebookSDK();
    if (!window.fbEventsBound) { return bindFacebookEvents(); }
};

// Bind to jquery ready
$(document).ready(ready);
$(document).on("turbolinks:load", ready);
