import { DirectUpload } from "@rails/activestorage"
import ImageResize from "@taoqf/quill-image-resize-module/image-resize.min";
import Quill from 'quill/quill';
export default Quill;

const MAX_FILE_SIZE = 1000000

Quill.register('modules/imageResize', ImageResize);

const ready = function() {
    console.log('ready')

    var input = document.querySelector('input[class=quill-content]')
    if(input == null){
        return
    }

    var quill = new Quill('#editor-container', {
        modules: {
            toolbar: [
                // 'code', 
                { header: [ 1, 2, 3, 4, 5, 6, false] },
                { size: [] },
                { color: [] },
                { background: [] },
                'bold', 
                'italic', 
                'underline', 
                'strike',
                // { 'script': 'super'},
                // { 'script': 'sub' },
                'link',
                'blockquote', 
                // 'code-block', 
                'image',
                'video',
                { list: 'ordered' }, 
                { list: 'bullet' },
                { align: ['center', 'right', 'justify', false]},
                { indent: '-1'},
                { indent: '+1'},
            // fone toolbar
            ],
            imageResize: {
                displaySize: true,
                displayStyles: {
                    backgroundColor: 'black',
                    border: 'none',
                    color: 'white'
                },
                modules: [ 'Resize', 'DisplaySize', /*'Toolbar'*/ ]
            }
        },
        value: input.value,
        theme: 'snow'
    });

    document.querySelector('form').onsubmit = function () {
        // var input = document.querySelector('input[class=quill-content]');
        input.value = quill.root.innerHTML
    };

    // More on this in a bit!
    quill.getModule('toolbar').addHandler('image', () => {
        importImage(quill);
    });

    var importImage = function (textEditor) {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.click();

        input.onchange = () => {
            const file = input.files[0];

            if (file.size > MAX_FILE_SIZE) {
                alert("Only support attachment files upto size 1MB!")
                return
            }

            // Ensure only images are uploaded
            if (/^image\//.test(file.type)) {
                uploadImage(textEditor, file);
            } else {
                alert('Only images allowed');
            }
        };
    };

    const uploadImage = function (textEditor, file) {
        var fd = new FormData();
        fd.append('blob', file);

        var upload = new DirectUpload(file, '/rails/active_storage/direct_uploads')
        upload.create((error, blob) => {
            if (error) {
                console.log(error)
            } else {
                insertImage(
                    textEditor,
                    `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`
                    );
            }
        });
    };

    const insertImage = function (textEditor, fileUrl) {
        const range = textEditor.getSelection();
        textEditor.insertEmbed(range.index, 'image', fileUrl);
    };


    const videoHandler = function () {
        let url = prompt("Enter Video URL: ");
        url = getVideoUrl(url);
        let range = quill.getSelection();
        if (url != null) {
            let delta = quill.insertEmbed(range, 'video', url);
        }
    }

    const getVideoUrl = function (url) {

        let match = url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/) ||
        url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/) ||
        url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/);
        
        if (match && match[2].length === 11) {
            return ('https') + '://www.youtube.com/embed/' + match[2] + '?showinfo=0';
        }
        if (match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/)) { // eslint-disable-line no-cond-assign
            return (match[1] || 'https') + '://player.vimeo.com/video/' + match[2] + '/';
        }
        return null;
    }

    quill.getModule('toolbar').addHandler('video', videoHandler);
}

// Bind to jquery ready
// $(document).ready(ready);
$(document).on("turbolinks:load", ready);