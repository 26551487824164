// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import 'channels'
import 'jquery'
import 'bootstrap/dist/js/bootstrap'
import 'owl.carousel'
import '../vendor/jquery.waypoints.min'

import toastr from 'toastr/toastr'
window.toastr = toastr

// import '../vendor/parallax.min'
import '../custom/responsive-image'
import '../custom/mobile'
import '../custom/application'
import '../custom/newsletter'
import '../custom/smooth_scroll'
import '../custom/google_analytics'
import '../custom/social_compatibility'
import '../custom/quill-editor'
