/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const ready = function() { 
    // Subscription form
    $('#newsletter-form').submit(function(e) {
        // avoid to execute the actual submit of the form.
        e.preventDefault();
        
        const form = $(this);
        const feed = $("#feedback");

        const success = function(data, textStatus, jqXHR) {
            // textStatus is always success you have to check data.result
            // {result: "error", msg: "0 - Please enter a value"}
            // {result: "success", msg: "Almost finished... We need to confirm your email address. Please click the link in the email we just sent you."}
            if (data.result === "success") {
                // CSS
                feed.removeClass('error');
                feed.addClass('success');
                // Disable submit button 
                form.children('button').prop("disabled", true);
            } else {
                feed.addClass('error');
                feed.removeClass('success');
            }
            // Show the message
            feed.text(data.msg);
            // Hide the form
            return form.hide();
        };

        const error = function(jqXHR, textStatus, errorThrown) { 
            // This is called actually when doesn't connect or other weird stuff
            console.log("error", textStatus, errorThrown);
            // Show an error message and hide the form
            feed.addClass('error');
            feed.removeClass('success');
            feed.text('Reload the page and please retry.');
            return form.hide();
        };

        // Post the data
        return $.ajax({ 
            url: '//dbprogolf.us12.list-manage.com/subscribe/post-json?u=d857e734520e42b1ffd83ac0f&id=207da431d3',
            type: 'POST',
            data: form.serialize(),
            success,
            error,
            contentType: 'application/json; charset=utf-8',
            jsonp: 'c',
            dataType: 'jsonp'
        });
    });

    // Hide things
    $('#mce-FNAME').hide();
    $('#newsletter-form button').hide();
    $('#newsletter-button').show();

    $('#newsletter-button').click(function(e) {
        // just for the effect
        const mailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (mailRegex.test($('#mce-EMAIL').val())) {
            const button = $(this);
            // Stylng submit button
            button.fadeOut();
            // Show name field
            $('#mce-FNAME').show();
            return $('#newsletter-form button').fadeIn();
        }
    });


    // Animate the newsletter submit form
    return $('#newsletter').waypoint({
        handler() {
            return $("#newsletter").addClass("shake");
        },
        context: '#overflow-scroll-offset',
        offset: '90%'
    });
};

$(document).ready(ready);
$(document).on('turbolinks:load', ready); 