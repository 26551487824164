/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const setFixedHeight = function(e) {
    e = $(e);
    const cssProps = {
        'height': e.height(),
        'min-height': 'auto'
       };
    return e.css(cssProps);
   };

const fun = function(e) {
	e = $(e);
	e.removeAttr('style');

	return setTimeout(() => setFixedHeight(e)
	, 500);
};

const ready = function() {

    $("[class*=page-]").each((i, e) => setFixedHeight(e));
    return $("[class=tip-image]").each((i, e) => setFixedHeight(e));
   };


const orientationChange = function() {
	// if window.orientation == 0
	// 	# Portrait
	// else
	// 	# Landscape
	$("[class*=page-]").each((i, e) => fun(e));
	return $("[class=tip-image]").each((i, e) => fun(e));
};

$(window).on("orientationchange", orientationChange);
$(document).on('turbolinks:load', ready);
