/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Smooth Scroll -.-
const ready = function() {
    // Disable manual scrolling temporary
    // left: 37, up: 38, right: 39, down: 40,
    // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
    const scrollKeys = {32: 1, 33: 1, 34: 1, 37: 1, 38: 1, 39: 1, 40: 1};

    const preventDefault = function(e) {
        e = e || window.event;
        if (e.preventDefault) { e.preventDefault(); }
        return e.returnValue = false;
    };

    const preventDefaultForScrollKeys = function(e) {
        if (scrollKeys[e.keyCode]) {
            preventDefault(e);
            return false;
        }
    };

    const disableScroll = function() {
        if (window.addEventListener) { // older FF
            window.addEventListener('DOMMouseScroll', preventDefault, false);
        }

        window.onwheel = preventDefault; // modern standard
        window.onmousewheel = (document.onmousewheel = preventDefault); // older browsers, IE
        window.ontouchmove  = preventDefault; // mobile
        return document.onkeydown  = preventDefaultForScrollKeys;
    };

    const enableScroll = function() {
        if (window.removeEventListener) {
            window.removeEventListener('DOMMouseScroll', preventDefault, false);
        }

        window.onmousewheel = (document.onmousewheel = null);
        window.onwheel = null;
        window.ontouchmove = null;
        return document.onkeydown = null;
    };


    const smoothScroll = function(hash, timeout) {
        // Check if an element was found
        const targetElement = $(hash);
        if (targetElement.length) {
            // Timeout param to wait the navbar to collapse (otherwise height is wrong) or just wait to animate after page loaded
            return setTimeout(function() {

                const windowOff = $(window).scrollTop();
                let targetOff = targetElement.offset().top;

                // If target is < navbar scroll to top
                if (targetOff <= 100) { // navbar max-height
                    targetOff = 0;
                } else {
                    targetOff -= 50; // remove the height of the navbar
                }

                // Min + variable param
                let speed = 250 + Math.abs(windowOff - targetOff);
                speed = Math.min(3000, speed);

                // console.log("to=", targetOff)
                // console.log("wo=", windowOff)
                // console.log("speed=", speed)

                // Disable temporary manual scroll and prevent default
                disableScroll();
                // Change hash
                window.location.hash = "@" + hash.replace('#','');
                // Do the scroll
                return $('html,body').animate({scrollTop: targetOff}, speed, enableScroll);
            }

            , timeout);
        }
    };

    // Smooth scroll for link with .scroll class
    $("a.scroll[href*='#']:not([href='#'])").click(function() {
        if ((window.location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'')) &&
           (window.location.hostname === this.hostname)) {
            // Collapse the navbar and the dropdown menu
            $('.navbar-collapse').collapse('hide');  // 'toggle'
            $(this).closest(".dropdown-menu").prev().dropdown('toggle');
            // Scroll smoothly
            smoothScroll(this.hash.replace('#@', '#'), 250);
            // Avoid browser default behaviour (I suppose)
            return false;
        }
    });

    // Replace # with #@ in all links containing # to avoid browser default behaviour
    $("a.scroll[href*='#']:not([href='#'])").each(function() {
        return $(this).attr('href', $(this).attr('href').replace('#', '#@'));
    });

    // SmoothScroll if a hash is found in the URL (only profile and lessons)
    if (window.location.pathname.match('lesson|profile')) {
        return smoothScroll(window.location.hash.replace('#@', '#'), 500);
    }
};

$(document).ready(ready);
$(document).on('turbolinks:load', ready);
